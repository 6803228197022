<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!-- <a-col :md="8" :sm="24">
              <a-form-item :label="$t('服务管理.后巷屋管理.封面')" prop="cover">
                <a-input v-model="queryParam.cover" placeholder="请输入封面" allow-clear />
              </a-form-item>
            </a-col> -->
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="视频地址" prop="videoUrl">
                <a-input v-model="queryParam.videoUrl" placeholder="请输入视频地址" allow-clear />
              </a-form-item>
            </a-col> -->
            <template>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('服务管理.后巷屋管理.定金')" prop="deposit">
                  <a-input
                    v-model="queryParam.deposit"
                    :placeholder="$t('服务管理.后巷屋管理.请输入定金')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('服务管理.后巷屋管理.预估价格')" prop="estimatedPrice">
                  <a-input
                    v-model="queryParam.estimatedPrice"
                    :placeholder="$t('服务管理.后巷屋管理.请输入预估价格')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('服务管理.后巷屋管理.标题')" prop="title">
                  <a-input v-model="queryParam.title" :placeholder="$t('服务管理.后巷屋管理.请输入标题')" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 6) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['laneway:houses:add']">
          <a-icon type="plus" />{{ $t('通用.新增') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['laneway:houses:edit']"
        >
          <a-icon type="edit" />{{ $t('通用.修改') }}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['laneway:houses:remove']">
          <a-icon type="delete" />{{ $t('通用.删除') }}
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['laneway:houses:export']">
          <a-icon type="download" />{{ $t('通用.导出') }}
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="cover" slot-scope="text, record">
          <img v-viewer :src="record.cover" style="width: 50px; height: 50px; border-radius: 4px" />
        </span>
        <template slot="videoUrl" slot-scope="text, record">
          <video width="50" height="50" controls style="border-radius: 4px; margin-top: 5px">
            <source :src="record.videoUrl" type="video/mp4" />
          </video>
        </template>
        <template slot="picUrl" slot-scope="text, record">
          <a-carousel autoplay>
            <a-carousel-item v-for="(image, index) in record.picUrl.split(',')" :key="index">
              <img
                :src="image"
                v-viewer
                alt=""
                style="width: 60px; height: 50px; border-radius: 4px; margin: 0px 3px"
              />
            </a-carousel-item>
          </a-carousel>
        </template>
        <template slot="content" slot-scope="text, record">
          <div v-html="record.content" class="duanluo"></div>
        </template>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['laneway:houses:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['laneway:houses:edit']">
            <a-icon type="edit" />{{ $t('通用.修改') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['laneway:houses:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['laneway:houses:remove']">
            <a-icon type="delete" />{{ $t('通用.删除') }}
          </a>
          <a-divider type="vertical" />
          <a @click="handleWatch(record)"> <a-icon type="search" />{{ $t('通用.查看服务') }} </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <!-- 弹框 -->
      <search-form ref="searchFormRefs" :data="tableData" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageHouses, listHouses, delHouses } from '@/api/laneway/houses'
import { pageHousesServe, listHousesServe, delHousesServe } from '@/api/laneway/housesServe'

import CreateForm from './modules/CreateForm'
import SearchForm from './modules/SearchForm.vue'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Houses',
  components: {
    CreateForm,
    SearchForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      tableSize: '',
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        cover: null,
        videoUrl: null,
        picUrl: null,
        deposit: null,
        estimatedPrice: null,
        title: null,
        content: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '主键',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '封面',
          dataIndex: 'cover',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'cover' },
        },
        {
          title: '视频',
          dataIndex: 'videoUrl',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'videoUrl' },
        },
        {
          title: '图片',
          dataIndex: 'picUrl',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'picUrl' },
          width: '160px',
        },
        {
          title: '定金',
          dataIndex: 'deposit',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '预估价格',
          dataIndex: 'estimatedPrice',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'content' },
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      tableData: [],
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询后巷屋列表 */
    getList() {
      this.loading = true
      pageHouses(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        cover: undefined,
        videoUrl: undefined,
        picUrl: undefined,
        deposit: undefined,
        estimatedPrice: undefined,
        title: undefined,
        content: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('服务管理.服务内容管理.确认删除所选中数据'),
        content: this.$t('服务管理.服务内容管理.当前选中编号为') + ids,
        okText: this.$t('通用.确认'),
        cancelText: this.$t('通用.取消'),
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delHouses(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'laneway/lanewayHouses/export',
            {
              ...that.queryParam,
            },
            `后巷屋_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    // 打开弹框
    handleWatch(row) {
      pageHousesServe({
        lanewayHousesId: row.id,
        type: null,
        name: null,
        money: null,
        sort: null,
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        // this.list = response.data.records
        // this.total = response.data.total
        // this.loading = false
        // console.log(res)
        this.tableData = res.data
        this.$refs.searchFormRefs.onOpen()
      })
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'id',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Cover',
            dataIndex: 'cover',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'cover' },
          },
          {
            title: 'video',
            dataIndex: 'videoUrl',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'videoUrl' },
          },
          {
            title: 'Image',
            dataIndex: 'picUrl',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'picUrl' },
            width: '160px',
          },
          {
            title: 'deposit',
            dataIndex: 'deposit',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'estimated Price',
            dataIndex: 'estimatedPrice',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'title',
            dataIndex: 'title',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Content',
            dataIndex: 'content',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'content' },
          },
          {
            title: 'CreateTime',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'Operation',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
        'zh-CN': [
          {
            title: '主键',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '封面',
            dataIndex: 'cover',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'cover' },
          },
          {
            title: '视频',
            dataIndex: 'videoUrl',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'videoUrl' },
          },
          {
            title: '图片',
            dataIndex: 'picUrl',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'picUrl' },
            width: '160px',
          },
          {
            title: '定金',
            dataIndex: 'deposit',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '预估价格',
            dataIndex: 'estimatedPrice',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '标题',
            dataIndex: 'title',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '内容',
            dataIndex: 'content',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'content' },
          },
          {
            title: '插入时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },

          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
<style scoped lang="less">
/deep/.ant-table-wrapper {
  .duanluo p {
    margin-bottom: 0px !important;
  }
}
</style>
